<div *ngIf="vehicle?.fuelLevel || vehicle?.oemConnected">
  <ysh-fuel-status [vehicle]="vehicle"></ysh-fuel-status>
</div>

<ion-item no-padding no-border *ngIf="vehicle?.oemConnected">
  <ion-label>Preferences</ion-label>
  <p>At what tank level do you want a gas alert?</p>
  <ion-range
    (ionChange)="didChangeFuelGaugeThreshold()"
    [(ngModel)]="fuelTheshold"
    min="0"
    max="4"
    step="1"
    snaps="true"
  >
    <ion-label range-left>E</ion-label>
    <ion-label range-right>F</ion-label>
  </ion-range>
</ion-item>
