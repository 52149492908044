<ion-header>
  <ion-toolbar>
    <ion-buttons start>
      <ion-button (click)="didTapBack()">
        <ion-icon slot="icon-only" name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="typed-flow flow" padding>
  <ion-label class="flow__title" stacked>Please confirm your vehicle info</ion-label>
  <ion-input
    [readonly]="true"
    [value]="selectedVehicle?.nickname"
    (click)="didTapVehicleField()"
    (ionFocus)="didTapVehicleField()"
    placeholder="Add Vehicle"
    icon-chevron-down
  ></ion-input>
  <button ysh-button-block [disabled]="!validates()" (click)="didTapContinue()">
    Confirm Vehicle
  </button>
</ion-content>
