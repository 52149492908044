<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button name="close" (click)="didTapDismiss()">
        <ion-icon name="close-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="didTapNext()" [disabled]="!cancellationNotes">Next</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="cancellation-notes-container cancellation-notes">
    <div class="cancellation-notes__title">{{title}}</div>
    <div class="cancellation-notes__notes-area">
      <ion-textarea placeholder="Enter Note" [(ngModel)]="cancellationNotes" #notes></ion-textarea>
    </div>
  </div>
</ion-content>
