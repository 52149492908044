<ysh-bottom-sheet (onDidDismiss)="dismiss()">
  <div class="service-sheet">
    <div class="service-brief">
      <ysh-service-icon [serviceImage]="serviceGroup.thumbnail"></ysh-service-icon>
      <div class="service-tagline">
        Add <strong>{{serviceGroup.title}}</strong> to your Yoshi day?
      </div>
    </div>
    <div class="service-preferences">
      <div class="row-item service-preferences__subtype" *ngIf="serviceGroup.services.length > 1">
        <ion-item>
          <ion-label>Option</ion-label>
          <ion-select class="select-box" [value]="selectedService" [(ngModel)]="selectedService">
            <ion-select-option *ngFor="let subtype of serviceGroup.services;" [value]="subtype"
              >{{subtype.name}}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </div>
      <div
        class="row-item service-preferences__frequency"
        *ngIf="serviceGroup.basicService?.frequencyOptions?.length"
      >
        <ion-item>
          <ion-label>Frequency</ion-label>
          <ion-select
            [(ngModel)]="selectedFrequency"
            class="select-box"
            [value]="selectedFrequency"
          >
            <ion-select-option
              *ngFor="let option of serviceGroup.basicService?.frequencyOptions; index as i"
              [value]="option"
            >
              Every {{option | titleForFrequency}}
            </ion-select-option>
            <ion-select-option value="0">One Time</ion-select-option>
          </ion-select>
        </ion-item>
      </div>
    </div>
    <ysh-add-service
      displayMode="block"
      (didTapAddService)="didTapAddService()"
      [price]="selectedService.price"
    >
    </ysh-add-service>
  </div>
</ysh-bottom-sheet>
