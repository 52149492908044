<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="didTapBack()">
        <ion-icon name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="flow" padding>
  <div class="flow-item">
    <ion-item no-padding no-border>
      <ion-label class="flow__title">What type of fuel do you use?</ion-label>
    </ion-item>
    <button
      class="formatted-button"
      *ngFor="let type of fuelTypes"
      (click)="didSelectFuelType(type)"
    >
      <span class="formatted-button__title">{{type.description}}</span>
    </button>
  </div>
</ion-content>
<ion-footer></ion-footer>
