<div
  class="add-service-container {{added ? 'added' : ''}}"
  [ngClass]="displayMode"
  (click)="didTap($event)"
>
  <div class="action">
    {{action}}
  </div>
  <ng-container *ngIf="!added && price > 0">
    <div class="separator">
      |
    </div>
    <div class="price">
      {{price | yshCurrency}}
    </div>
  </ng-container>
</div>
