<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="didTapDismiss()">
        <ion-icon name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #content (ionScroll)="dismissKeyboard()" class="page-vehicle-make typed-flow flow">
  <div class="flowItem active">
    <ion-label class="flow__title">What is the make of your car?</ion-label>
    <ion-searchbar
      #searchbar
      class="searchbar"
      placeholder="Search Makes"
      glob
      [(ngModel)]="search"
      (ionChange)="onMakeFilterChanged()"
    ></ion-searchbar>
    <ion-spinner *ngIf="!filteredMakes.length" name="dots"></ion-spinner>
    <ion-list>
      <ion-item button *ngFor="let make of filteredMakes" (click)="didSelectMake(make)">
        {{make.make}}
      </ion-item>
    </ion-list>
  </div>
</ion-content>
