<div
  class="ring vehicle-image-component"
  [ngClass]="{
  highlighted: imageReady && displayMode === 'highlighted',
  grayed: imageReady && displayMode === 'grayed',
  fuel_api: isFuelApiImage()
}"
  (click)="didTapVehicleImage()"
>
  <div class="icon-add-img" *ngIf="canAddImg">
    <ion-icon name="add-sharp"></ion-icon>
  </div>
  <div class="container">
    <img
      [src]="src || 'assets/images/vehicle-placeholder.svg'"
      (load)="imageDidLoad()"
      (error)="errorLoadingImage()"
    />
  </div>
</div>
