<div *ngIf="frequentlyAskedQuestions.length" class="faq-component">
  <h4>FAQs</h4>
  <div class="question-container" *ngFor="let ques of frequentlyAskedQuestions; index as i">
    <span class="statement" (click)="expandedEleMap[i] = !expandedEleMap[i]">
      {{ques.question}}
      <span class="collapse" *ngIf="expandedEleMap[i]">-</span>
      <span class="expand" *ngIf="!expandedEleMap[i]">+</span>
    </span>
    <p class="answer" *ngIf="expandedEleMap[i]">{{ques.answer}}</p>
  </div>
</div>
