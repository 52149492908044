<div class="ysh-dropdown-component" *ngIf="options?.length">
  <div class="count-label" *ngIf="showCountLabel">{{options.length}} Options</div>
  <ion-item no-border no-padding>
    <ion-select
      [placeholder]="placeholder"
      [interfaceOptions]="selectOptions"
      (ionChange)="didSelectOption($event)"
      [(ngModel)]="selectedIndex"
    >
      <ion-select-option *ngFor="let option of options; index as i" [value]="i"
        >{{option}}</ion-select-option
      >
    </ion-select>
  </ion-item>
</div>
