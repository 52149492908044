<ion-header>
  <ion-toolbar>
    <ion-buttons start>
      <ion-button (click)="didTapDismiss()">
        <ion-icon slot="icon-only" name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="typed-flow flow">
  <div class="flowItem active">
    <ion-label class="flow__title" stacked>Where do you normally park your car?</ion-label>
    <ion-input
      [value]="formData.locationName"
      (click)="didTapNewAddress()"
      (ionFocus)="didTapNewAddress()"
      [ngClass]="{'outline' :
      outlineInputStyle}"
      [readonly]="true"
      placeholder="Enter Address"
    ></ion-input>

    <ion-row id="locationButton" *ngIf="!addressValidates()">
      <ion-col>
        <button ysh-button-block action="secondary" (click)="didTapGetLocation()">
          Use Current Location
        </button>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <button ysh-button-block [disabled]="!addressValidates()" (click)="didTapDone()">
          Done
        </button>
      </ion-col>
    </ion-row>
  </div>
</ion-content>
