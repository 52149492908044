<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button name="close" (click)="didTapDismiss()">
        <ion-icon name="close-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="confirm-cancellation confirm-cancellation-container">
    <div class="confirm-cancellation__main-text">
      <p>{{mainText()}}</p>
      <p
        class="delivery-charge-disclaimer"
        *ngIf="membershipCancelled && showDeliveryChargeDisclaimer()"
      >
        {{deliveryChargeDisclaimer()}}
      </p>
    </div>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ysh-curved-actions-block>
      <button
        ysh-button-block
        action="tertiary"
        *ngIf="membershipCancelled && showDeliveryChargeDisclaimer()"
        (click)="didTapNevermind()"
      >
        {{keepServicesText}}
      </button>

      <button
        ysh-button-block
        [action]='membershipCancelled ? "primary" : "destructive"'
        (click)="didTapConfirm()"
      >
        {{confirmBtnText()}}
      </button>

      <button
        ysh-button-block
        action="tertiary"
        *ngIf="!membershipCancelled"
        (click)="didTapNevermind()"
      >
        {{nevermindText}}
      </button>
    </ysh-curved-actions-block>
  </ion-toolbar>
</ion-footer>
