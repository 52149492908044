<ion-content>
  <div class="time-slot-selector" slot="fixed">
    <div class="time-slot-selector__header header">
      <ysh-order-flow-header
        (onBackTapped)="didTapBack()"
        [isUpdatingOrder]="props.isModal"
        [service]="props.service"
        (onDismissTapped)="didTapDismiss()"
        (onAddressUpdated)="didUpdateAddress($event)"
      >
      </ysh-order-flow-header>
    </div>

    <div class="time-slot-selector__content">
      <div
        class="time-slot-selector__content__slots"
        *ngIf="form.availableSlots && form.availableSlots.length > 1"
      >
        <button
          ysh-pill-button
          *ngFor="let slot of form.availableSlots"
          [selected]="checkSelectedSlot(slot)"
          [disabled]="!slot.available"
          (click)="didTapOnSlot(slot)"
        >
          {{slot.startTimeString}}
        </button>
      </div>
      <div class="time-slot-selector__content__caption">
        This service is estimated to take {{ props.service?.stopDuration }} minutes.
      </div>
      <button ysh-button-block size="large" (click)="didSelectTimeSlot(form.slot!)">
        Confirm {{ props.service?.serviceType.title || "Delivery" }} Time
      </button>
    </div>
  </div>
</ion-content>
