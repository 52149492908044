<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-icon
        name="close-sharp"
        *ngIf="modal"
        slot="icon-only"
        (click)="didTapDismiss()"
      ></ion-icon>
    </ion-buttons>
    <ion-title>Edit Vehicle</ion-title>
    <ion-buttons *ngIf="!vehicle.oemConnected" slot="end">
      <ion-button fill="clear" [disabled]="!dirty || !validates()" (click)="didTapSave()"
        >Save</ion-button
      >
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="!vehicle.oemConnected" class="settings">
  <ion-list>
    <div class="vehicle-avatar">
      <ysh-vehicle-image
        [src]="vehicleImg || vehicle.vehicleImageUrl"
        large
        [selectedVehicle]="vehicle"
        (onTap)="didTapAvatar()"
        [canAddImg]="true"
      ></ysh-vehicle-image>
    </div>
    <ion-item>
      <ion-label>Nickname</ion-label>
      <ion-input
        #nicknameInputField
        [(ngModel)]="params.nickname"
        placeholder="Nickname"
        (input)="didChangeNickname()"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Make</ion-label>
      <ion-select
        placeholder="{{this.vehicle.vehicleMake.make || 'Select'}}"
        (ionChange)="didChangeMake($event)"
        icon-chevron-down
      >
        <ion-select-option *ngFor="let make of carMakes" [value]="make"
          >{{make.make}}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item *ngIf="carModels?.length">
      <ion-label>Model</ion-label>
      <ion-select placeholder="Select" (ionChange)="didChangeModel($event)" icon-chevron-down>
        <ion-select-option *ngFor="let model of carModels" value="{{model.uid}}"
          >{{model.model}}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label>License</ion-label>
      <ion-input
        #licenseInputField
        [(ngModel)]="params.license"
        placeholder="Add license"
        (input)="didChangeLicense()"
        [disabled]="vehicle.license ? 'true' : 'false'"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>VIN</ion-label>
      <ion-input
        #vinInputField
        [(ngModel)]="params.vin"
        placeholder="Add VIN"
        (input)="didChangeVin()"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Color</ion-label>
      <ion-select
        [(ngModel)]="params.color"
        placeholder="{{vehicle.color || 'Choose color'}}"
        (ionChange)="didSelectColor()"
        icon-chevron-down
      >
        <ion-select-option *ngFor="let color of colors">{{color}}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label>Fuel Type</ion-label>
      <ion-select
        [(ngModel)]="params.fuelTypeUid"
        placeholder="{{vehicle.fuelType?.description || 'Choose Fuel Type'}}"
        (ionChange)="didChangeFuelType()"
        icon-chevron-down
      >
        <ion-select-option *ngFor="let type of fuelTypes" [value]="type.uid"
          >{{type.description}}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-row>
      <ion-col padding>
        <button ysh-button-block action="destructive" (click)="didTapDelete()" type="submit">
          Delete Vehicle
        </button>
      </ion-col>
    </ion-row>
  </ion-list>
</ion-content>
<ion-content class="settings" *ngIf="vehicle.oemConnected">
  <ion-item-group>
    <ion-item-divider style="border-bottom: none !important">
      <ion-row align-items-center>
        <ion-col col-2>
          <img [src]="iconUrl" />
        </ion-col>
        <ion-col col-10 *ngIf="vehicle.onstar">
          Your {{vehicle.vehicleModel.model}} is connected to OnStar&trade;
        </ion-col>
        <ion-col col-10 *ngIf="vehicle.toyotaConnect">
          Your {{vehicle.vehicleModel.model}} is connected to Toyota Connect&trade;
        </ion-col>
      </ion-row>
    </ion-item-divider>
    <ion-item-divider> Vehicle Details </ion-item-divider>
    <ion-item class="vehicle-details">
      <div>
        <label>Make/Model:</label>
        <span> {{vehicle.vehicleMake.make + ' ' + vehicle.vehicleModel.model}}</span>
      </div>
      <div>
        <label>VIN:</label>
        <span>{{vehicle.vin}}</span>
      </div>
    </ion-item>
    <ion-item>
      <ion-label>Nickname</ion-label>
      <ion-input
        [(ngModel)]="params.nickname"
        placeholder="Nickname"
        (input)="didChangeNickname()"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>License</ion-label>
      <ion-input
        [(ngModel)]="params.license"
        placeholder="Add license"
        (input)="didChangeLicense()"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Color</ion-label>
      <ion-select
        [(ngModel)]="params.color"
        placeholder="{{vehicle.color || 'Choose color'}}"
        (ionChange)="didSelectColor()"
        icon-chevron-down
      >
        <ion-select-option *ngFor="let color of colors">{{color}}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item-divider> At what tank level do you want low fuel alerts? </ion-item-divider>
    <ion-item>
      <ion-range
        (ionChange)="didChangeFuelGaugeThreshold()"
        [(ngModel)]="fuel_gauge_threshold"
        min="0"
        max="4"
        step="1"
        snaps="true"
      >
        <ion-label range-left>E</ion-label>
        <ion-label range-right>F</ion-label>
      </ion-range>
    </ion-item>
    <ion-item>
      <ion-label>Fuel Type</ion-label>
      <ion-select
        [(ngModel)]="params.fuelTypeUid"
        placeholder="{{vehicle.fuelType?.description || 'Choose Fuel Type'}}"
        (ionChange)="didChangeFuelType()"
        icon-chevron-down
      >
        <ion-select-option *ngFor="let type of fuelTypes" [value]="type.uid"
          >{{type.description}}</ion-select-option
        >
      </ion-select>
    </ion-item>
  </ion-item-group>

  <div class="button-wrapper">
    <button [disabled]="!dirty" (click)="didTapSave()" ysh-button-block>Save</button>
  </div>
</ion-content>
