<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="didTapDismiss()">
        <ion-icon name="close-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content padding>
  <h1>Frequently Asked Questions</h1>
  <div class="faqs">
    <div class="faq" *ngFor="let faq of props.faq">
      <h5 class="faq__question">{{faq.question}}</h5>
      <p class="faq__answer">{{faq.answer}}</p>
    </div>
  </div>
</ion-content>
