<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button name="close" (click)="didTapDismiss()">
        <ion-icon name="close-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="membership-benefits">
    <div class="membership-benefits__title">{{title}}</div>
    <div class="membership-benefits__details">
      <div class="yoshi-benefits">
        <div class="yoshi-benefits__item" *ngFor="let benefit of yoshiBenefits">
          <span class="tag">{{benefit.tag}}</span>{{" "}}<span>{{benefit.detail}}</span>
        </div>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar no-padding>
    <ysh-curved-actions-block>
      <button ysh-button-block (click)="didTapKeepMembership()">Keep My Membership</button>
      <button ysh-button-block action="tertiary" (click)="didTapCancelMembership()">
        Cancel Membership
      </button>
    </ysh-curved-actions-block>
  </ion-toolbar>
</ion-footer>
