<div class="empty-state">
  <div class="empty-state__icon">
    <img [src]="imageSource || 'assets/images/v5/empty-state.png'" alt="" />
  </div>
  <h2 *ngIf="title">{{title}}</h2>
  <p *ngIf="description">{{description}}</p>
  <button *ngIf="buttonText" ysh-button (click)="didTapButton()">
    {{buttonText}}
  </button>
  <p *ngIf="bottomText">{{bottomText}}</p>
</div>
