<ion-item no-padding no-border>
  <div *ngIf="recurrence" class="scheduled-days">
    <span class="scheduled-days__repeat-icon">
      <ion-icon name="repeat"></ion-icon>
    </span>
    <span class="days-string">{{recurrence}}</span>
  </div>

  <ion-label> Next {{service?.serviceType?.title}}</ion-label>
  <span class="one-time-only" *ngIf="oneTimeOnly">(ONE TIME ONLY)</span>
  <p *ngIf="visit?.order.date as nextDate">{{nextDate | date:'EEEE LLLL dd'}} {{timeWindow}}</p>
  <p *ngIf="!visit?.order.date " class="not-scheduled">Not Scheduled</p>
  <p *ngIf="visit" class="delivery-address">{{visit.order.address.locationName}}</p>
</ion-item>
