<div class="simple-message-modal">
  <div class="backdrop" (click)="didTapBackdrop()"></div>
  <div class="content">
    <img *ngIf="image" [src]="image" [width]='imageWidth || "auto"' />
    <h4 class="simple-message-modal__title" *ngIf="title">{{title}}</h4>
    <p class="simple-message-modal__description" *ngIf="description" [innerHtml]="description"></p>
    <div class="simple-message-modal__actions">
      <button *ngIf="actionButtonText" ysh-button-block (click)="didTapModalAction()">
        {{actionButtonText}}
      </button>
      <button
        *ngIf="secondaryButtonText"
        ysh-button-block
        action="tertiary"
        (click)="didTapSecondaryButton()"
      >
        {{secondaryButtonText}}
      </button>
    </div>
  </div>
</div>
