<div
  class="service-card"
  (click)="didTap()"
  [ngClass]="classForService"
  [class.reverse]="serviceGroup.serviceType.presentation.reverseDisplay"
  [class.featured]="serviceGroup.isFeatured"
  [style.--background-color]="serviceGroup.serviceType.presentation.backgroundColor"
  [style.--foreground-color]="serviceGroup.serviceType.presentation.color"
>
  <div class="service-card__description">
    <img
      class="service-card__description__logo"
      *ngIf="serviceGroup.serviceType.logoUrl as logo"
      [src]="logo"
    />
    <h4 class="service-card__description__title">{{serviceGroup?.title}}</h4>
    <h4 class="service-card__description__summary">
      <span [innerHtml]="serviceGroup.shortDescription"></span>
      <strong *ngIf="!showAddBtn && priceString" style="white-space: nowrap">
        {{priceString}}
      </strong>
    </h4>
    <ysh-add-service
      *ngIf="showAddBtn"
      displayMode="small"
      [added]="serviceIsAdded"
      (didTapAddService)="didTapAddService($event)"
      [price]="serviceGroup.price"
    >
    </ysh-add-service>
  </div>
  <div class="service-card__image">
    <img-loader [useImg]="true" [src]="image"></img-loader>
  </div>
</div>
