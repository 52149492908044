<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button icon="chevron-back-sharp" *ngIf="!isModal"></ion-back-button>
      <button ysh-button-icon name="close" (click)="didTapDismiss()" *ngIf="isModal"></button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <h1 class="membership-heading">{{prompt}}</h1>
  <div class="membership-cards">
    <ysh-membership-card
      button
      (click)="didSelectPlan(annualTier)"
      *ngIf="annualTier"
      [tier]="annualTier"
      [fee]="annualTier.monthlyFee"
      [bestValue]="monthlyTier ? true : false"
      [selected]="selectedTier === annualTier"
    ></ysh-membership-card>

    <hr class="membership-cards__divider" />

    <ysh-membership-card
      button
      (click)="didSelectPlan(monthlyTier)"
      *ngIf="monthlyTier"
      [tier]="monthlyTier"
      [fee]="discountedMonthlyFee"
      [description]="discountDescription$ | async"
      [selected]="selectedTier === monthlyTier"
    ></ysh-membership-card>
  </div>

  <ysh-membership-benefits
    [showFreeTrialBenefit]="freeTrialInEffect"
    [visitsPerMonth]="maxVisitsPerMonth"
    [policy]="this.memberService.memberPolicy$ | async"
  ></ysh-membership-benefits>

  <div class="membership-buttons">
    <button
      (click)="didTapSubscribe(selectedTier!)"
      [disabled]="!selectedTier"
      ysh-button-block
      action="primary"
    >
      {{cta}}
    </button>
    <button
      class="skip-button"
      *ngIf="showSkipOption"
      (click)="didTapSkip()"
      ysh-button-block
      action="tertiary"
    >
      Skip Subscription — Pay {{deliveryFee}} Per Delivery
    </button>
  </div>
</ion-content>
