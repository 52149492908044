<ion-header>
  <ion-toolbar>
    <ion-title> Recalls</ion-title>
    <ion-buttons>
      <ion-button (click)="didTapDismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content background>
  <ion-list *ngIf="recalls.length">
    <ion-item-divider></ion-item-divider>
    <ion-item
      detail="true"
      *ngFor="let recall of recalls"
      class="vehicle-recall-item"
      (click)="didTapRecallItem(recall)"
    >
      <div>
        <p class="vehicle-recall-item__date">{{recall.recordCreationDate | date:'MMM d, y'}}</p>
        <p class="vehicle-recall-item__sub-title">{{recall.componentDescription}}</p>
      </div>
    </ion-item>
  </ion-list>
</ion-content>
