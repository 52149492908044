<div
  padding
  class="header-content"
  [ngClass]="classForService"
  [style.--background-color]="service.serviceType.presentation.backgroundColor"
  [class.shadow]="showShadow"
>
  <div [ngClass]='{"title": true}' class="header-content-container">
    <h1 class="service-name" *ngIf="service.title">{{service.title}}</h1>
    <h1 class="service-tagline" [innerHTML]="description"></h1>
    <ng-content></ng-content>
    <div class="service-bg">
      <img-loader
        [useImg]="true"
        [src]="service?.imageUrl"
        [imgAttributes]="IMAGE_LOADER_ATTRIBUTES"
      ></img-loader>
    </div>
  </div>
</div>
