<ion-header>
  <ion-toolbar>
    <ion-title>Mileage</ion-title>
    <ion-buttons>
      <ion-button *ngIf="modal" (click)="didTapDismiss()"
        ><ion-icon name="close"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content background class="vehicle-mileage-content">
  <ion-list>
    <ion-item-divider class="total-miles">
      <p class="miles-count" [ngClass]="{'empty' : !mileages.length}">
        {{mileages.length ? (mileages[0].miles | number:'1.0':'en-US') : 0}}
      </p>
      <p class="miles-label">Miles</p>
    </ion-item-divider>
    <ion-item detail="false" class="user-input-miles">
      <input [(ngModel)]="userInputMiles" type="number" placeholder="Add Today's Odometer" />
      <button
        *ngIf="userInputMiles && userInputMiles > 0"
        ysh-button
        action="tertiary"
        (click)="didTapAddMiles()"
      >
        Save
      </button>
    </ion-item>

    <ion-item-divider *ngIf="mileages.length" no-label></ion-item-divider>

    <ng-container *ngFor="let mileage of mileages">
      <ion-item-sliding *ngIf="mileage.source === 'user'">
        <ion-item-options side="end">
          <ion-item-option (click)="deleteMileage(mileage.uid)" color="danger">
            Delete
          </ion-item-option>
        </ion-item-options>
        <ion-item class="mileage-item">
          <div class="icon"><p>{{(userService.currentUser$ | async)?.initials}}</p></div>
          <div>
            <p class="label">{{mileage.miles | number:'1.0':'en-US'}}</p>
            <p class="timestamp">{{mileage.createdAt | date: 'MMM d, y'}}</p>
          </div>
        </ion-item>
      </ion-item-sliding>

      <ion-item class="mileage-item" *ngIf="mileage.source !== 'user'">
        <img
          [src]="mileage.source === 'onstar' ? ICON_ONSTAR : mileage.source === 'toyota' ? ICON_TOYOTA : ICON_YOSHI"
          alt=""
          class="icon"
        />
        <div>
          <p class="label">{{mileage.miles | number:'1.0':'en-US'}}</p>
          <p class="timestamp">{{mileage.createdAt | date: 'MMM d, y'}}</p>
        </div>
      </ion-item>
    </ng-container>
  </ion-list>
  <ion-infinite-scroll (ionInfinite)="loadNextPage($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading More...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
