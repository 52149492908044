<ion-item no-padding no-border>
  <ion-label class="title-label">Tire Status</ion-label>
</ion-item>
<div class="tires">
  <div class="tires__column">
    <div class="tire">
      <ion-label>
        <span class="psi"
          >PSI:
          <span>{{pressures['left_front']?.value || 'N/A'}}</span>
        </span>
        <span class="tread"
          >TREAD:
          <span [ngClass]="treads['left_front']?.colorValue"></span>
        </span>
      </ion-label>
    </div>
    <div class="tire">
      <ion-label>
        <span class="psi"
          >PSI:
          <span>{{pressures['left_rear']?.value || 'N/A'}}</span>
        </span>
        <span class="tread"
          >TREAD:
          <span [ngClass]="treads['left_rear']?.colorValue"></span>
        </span>
      </ion-label>
    </div>
  </div>

  <div class="tires__chassis">
    <img src="assets/images/chassis.svg" />
  </div>

  <div class="tires__column">
    <div class="tire">
      <ion-label>
        <span class="psi"
          >PSI:
          <span>{{pressures['right_front']?.value || 'N/A'}}</span>
        </span>
        <span class="tread"
          >TREAD:
          <span [ngClass]="treads['right_front']?.colorValue"></span>
        </span>
      </ion-label>
    </div>
    <div class="tire">
      <ion-label>
        <span class="psi"
          >PSI:
          <span>{{pressures['right_rear']?.value || 'N/A'}}</span>
        </span>
        <span class="tread"
          >TREAD:
          <span [ngClass]="treads['right_rear']?.colorValue"></span>
        </span>
      </ion-label>
    </div>
  </div>
</div>

<ion-list *ngIf="vehicle" no-lines class="list">
  <ion-label class="title-label">Preferences</ion-label>
  <ion-item no-padding>
    <ion-label>Front PSI</ion-label>
    <ion-select
      icon-chevron-down
      no-border
      class="front"
      (ionChange)="didChangeRequestedFrontPSI($event.target.value)"
      [value]="requestedPsiFront"
    >
      <ion-select-option *ngFor="let option of psiOptions" [value]="option"
        >{{option}}</ion-select-option
      >
    </ion-select>
  </ion-item>

  <ion-item no-border no-padding>
    <ion-label>Rear PSI</ion-label>
    <ion-select
      icon-chevron-down
      no-border
      class="rear"
      (ionChange)="didChangeRequestedRearPSI($event.target.value)"
      [value]="requestedPsiRear"
    >
      <ion-select-option *ngFor="let option of psiOptions" [value]="option"
        >{{option}}</ion-select-option
      >
    </ion-select>
  </ion-item>
</ion-list>
