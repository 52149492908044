<div class="membership-benefits">
  <h2 class="membership-benefits__heading">Experience the benefits of subscription:</h2>
  <ul class="membership-benefits__list">
    <li *ngFor="let benefit of policy.promotionalDetails.benefits">{{benefit}}</li>
  </ul>
  <p class="membership-benefits__caption">
    Fuel and other service costs are separate. You may cancel via the app or by messaging Yoshi
    customer service.
    <span *ngIf="showFreeTrialBenefit">
      Your free trial will cancel automatically after the designated period, if you do not order any
      services.
    </span>
  </p>
</div>
