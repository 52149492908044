<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ng-container *ngIf="!modal">
        <ion-back-button icon="chevron-back-sharp"></ion-back-button>
      </ng-container>
      <ion-icon
        slot="icon-only"
        name="close-sharp"
        *ngIf="modal"
        class="dismiss-btn"
        (click)="didTapDismiss()"
      ></ion-icon>
    </ion-buttons>
    <ion-title>Vehicles</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="settings" background>
  <ion-list>
    <ion-item-divider></ion-item-divider>
    <ion-item button detail="true" (click)="didTapNewVehicle()">Add Vehicle</ion-item>
    <div *ngIf="(vehicles$ | async)?.length">
      <ion-item-divider></ion-item-divider>
      <ion-item-sliding *ngFor="let vehicle of vehicles$ | async">
        <ion-item
          detail="false"
          (click)="didSelectVehicle(vehicle)"
          class="vehicle-list-item"
          [class.selected]="vehicle.uid === (this.vehicleService.selectedVehicle$ | async)?.uid"
        >
          <div class="vehicle-details">
            <div class="vehicle-nick">{{vehicle.nickname}}</div>
            <div class="vehicle-license secondary-detail">
              License: {{vehicle.license || "Add license"}}
            </div>
            <div *ngIf="vehicle.onstar" class="onstar-subtitle color-light-gray secondary-detail">
              Connected to OnStar&trade;
            </div>
            <div
              *ngIf="vehicle.toyotaConnect"
              class="onstar-subtitle color-light-gray secondary-detail"
            >
              Connected to Toyota Connect&trade;
            </div>
          </div>
          <div class="check-mark">
            <ion-icon
              end
              name="checkmark-sharp"
              *ngIf="vehicle.uid === (this.vehicleService.selectedVehicle$ | async)?.uid"
            ></ion-icon>
          </div>
        </ion-item>
        <ion-item-options>
          <ion-item-option color="danger" (click)="didTapDelete(vehicle)">Delete</ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </div>
    <ion-spinner *ngIf="isLoading$ | async" class="center-horizontal"></ion-spinner>
  </ion-list>
</ion-content>
