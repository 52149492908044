<ion-content padding>
  <svg class="icon-pin">
    <path d="M64 32c0 22-32 56-32 56S0 54 0 32C0 14 14 0 32 0s32 14 32 32z" />
    <path
      d="M58 32C58 17.64 46.36 6 32 6S6 17.64 6 32s11.64 26 26 26 26-11.64 26-26zM30.6 45.028v-12.22L20.016 26.7a4.404 4.404 0 0 1-5.115.512 4.4 4.4 0 1 1 4.401-7.62 4.401 4.401 0 0 1 2.114 4.683L32 30.384l10.584-6.11a4.401 4.401 0 1 1 6.515 2.937 4.404 4.404 0 0 1-5.115-.512L33.4 32.81v12.219a4.402 4.402 0 1 1-2.8 0z"
    />
  </svg>
  <ion-text>
    <h2>{{props.serviceGroup.serviceType.title}} Scheduled!</h2>
    <p>Thank you for your order request! Your {{props.serviceGroup.serviceType.title.toLowerCase()}} is scheduled. We’ll send you a confirmation text message shortly with additional instructions. See you soon!</p>
    <p>To reschedule your inspection, text us directly at: <a href="tel:{{DEFAULT_SUPPORT_PHONE}}">{{formatPhoneNumber(DEFAULT_SUPPORT_PHONE)}}</a></p>
  </ion-text>
  <p class="footer-text">
    Have Questions?
    <a href="mailto:support@startyoshi.com?subject=Service Order Help">Contact Support</a>
  </p>
</ion-content>
