<ion-segment [(ngModel)]="page" *ngIf="!hideTabs">
  <ion-segment-button value="about">About</ion-segment-button>
  <ion-segment-button value="status">Status</ion-segment-button>
</ion-segment>

<div class="service-content about" *ngIf="page === 'about'">
  <div class="service-description" [innerHTML]="service?.description"></div>
  <div class="gallery" *ngIf="service?.serviceType?.photos?.length">
    <ysh-gallery [slides]="galleryPhotos" (onSlideTap)="didTapGalleryPhoto($event)"></ysh-gallery>
  </div>
  <ysh-faq
    [frequentlyAskedQuestions]="faqs"
    *ngIf="faqs?.length"
  ></ysh-faq>
</div>

<div *ngIf="page === 'status'" class="service-content">
  <ion-list no-lines class="list">
    <ng-content></ng-content>
  </ion-list>
</div>
