<ion-content>
  <div class="membership-discount membership-discount-container">
    <div class="membership-discount__nav-actions" (click)="didTapDismiss()">
      <div class="membership-discount__nav-actions--dismiss-btn">
        <ion-icon name="close-sharp"></ion-icon>
      </div>
    </div>
    <div class="membership-discount__title" *ngIf="!this.promoApplied">
      Can we offer you a {{discount | currency:'USD':'symbol':'1.0-0'}} membership discount to keep
      trying Yoshi?
    </div>
    <div class="membership-discount__title" *ngIf="this.promoApplied">
      A {{discount | currency:'USD':'symbol':'1.0-0'}} discount has been applied to your membership!
      Thank you for giving Yoshi another try. 🙌
    </div>

    <a
      class="membership-discount__no-thankyou"
      (click)="didDeclineOffer()"
      *ngIf="!this.promoApplied"
      >No, thank you</a
    >
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ysh-curved-actions-block>
      <button *ngIf="!promoApplied" ysh-button-block (click)="didAcceptOffer()">
        That would be great!
      </button>
      <button *ngIf="promoApplied" ysh-button-block (click)="didTapFinish()">Finish</button>
    </ysh-curved-actions-block>
  </ion-toolbar>
</ion-footer>
