<div class="loyalty-stars" [ngStyle]="{background: color && colorIsDark ? color : '#142846' }">
  <span class="loyalty-stars__prefix">Earn</span>
  <span class="loyalty-stars__amount">{{stars | number:'1.0-0'}}</span>
  <ion-icon
    class="loyalty-stars__icon"
    name="star"
    [ngStyle]="{color: color && !colorIsDark ? color : '#ffffff'}"
  ></ion-icon>
  <span class="loyalty-stars__postfix" *ngIf="postfix"> {{postfix}}</span>
</div>
