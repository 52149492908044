<ion-content fullscreen="true" class="photo-story-page">
  <div slot="fixed" class="story-container">
    <ion-header class="photo-story-page">
      <ion-toolbar>
        <ion-buttons slot="start">
          <button ysh-button-icon name="close" (click)="didTapDismiss()"></button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-slides
      #slides
      [pager]="photoStory.length > 1"
      (ionSlideTouchEnd)="didTapSlide($event)"
      (ionSlideDidChange)="updateActiveIndex()"
    >
      <ion-slide class="photo" *ngFor="let photo of photoStory">
        <img [src]="photo.url" />
      </ion-slide>
    </ion-slides>

    <div class="actions">
      <button ysh-button size="small" action="tertiary" (click)="didTapSetAsVehicleImage()">
        Set Vehicle Image
      </button>
      <button ysh-button size="small" (click)="didTapShare()">Share It!</button>
    </div>
  </div>
</ion-content>
