<div class="order-flow-header">
  <div class="map-container"></div>
  <div class="overlay"></div>
  <ion-item button detail="false" class="select-address" (click)="didTapSelectedAddress()">
    <ion-label> {{selectedAddress?.address?.details.description}} </ion-label>
    <ion-icon name="chevron-forward-sharp"></ion-icon>
  </ion-item>
  <div class="nav-actions">
    <ysh-order-flow-navbar
      (onBack)="didTapBack()"
      [showDismissBtn]="isUpdatingOrder && !hideBackbutton"
      [showBackBtn]="!isUpdatingOrder && !hideBackbutton"
      [showFaqBtn]="true"
      [service]="service"
      (onDismiss)="didTapDismiss()"
      [showInDarkTheme]="false"
    ></ysh-order-flow-navbar>
  </div>
</div>
