<ion-toolbar class="order-flow-navbar">
  <div class="actions">
    <button
      *ngIf="showDismissBtn"
      class="actions__dismiss-btn"
      ysh-button-icon
      name="close"
      [theme]='showInDarkTheme ? "dark" : "light"'
      (click)="didTapDismiss()"
    ></button>
    <button
      *ngIf="showBackBtn"
      class="actions__back-btn"
      ysh-button-icon
      name="back"
      [theme]='showInDarkTheme ? "dark" : "light"'
      (click)="didTapBack()"
    ></button>
    <button
      *ngIf="showFaqBtn && service?.serviceType?.faqs?.length"
      class="actions__faq"
      ysh-button-icon
      name="question"
      [theme]='showInDarkTheme ? "dark" : "light"'
      (click)="didTapFaq()"
    ></button>
  </div>
</ion-toolbar>
