<ion-content ion-fixed padding>
  <div class="container">
    <div *ngIf="flowIndex === 0" class="benefits">
      <div>
        <h3>Welcome {{(userService.currentUser$ | async)?.firstName}}!</h3>
        <p>You have some discounts waiting for you!</p>
      </div>
      <div>
        <div
          class="benefit"
          *ngIf="(memberService.freeTrialMemberBenefit$ | async) && (memberService.freeTrialInEffect$ | async)"
        >
          <div>
            <p class="text">{{(memberService.freeTrialMemberBenefit$ | async)!.displayName}}</p>
            <p class="expires" *ngIf="(memberService.freeTrialMemberBenefit$ | async)">
              exp. {{(memberService.freeTrialMemberBenefit$ | async)!.expiredAt | date: 'M/d' }}
            </p>
          </div>
          <p class="from">
            {{(memberService.freeTrialMemberBenefit$ | async)!.daysUntilExpiration}} days left<br />
            then {{memberService.discountedFeeAfterTrial$ | async |
            currency:'USD':'symbol':'1.0-2'}}/month
          </p>
        </div>
        <div class="benefit" *ngFor="let benefit of memberService.memberBenefits$ | async">
          <div>
            <p class="text" [innerHTML]="membershipBenefitDescription$(benefit) | async"></p>
            <p class="expires" *ngIf="benefit.expiredAt">
              exp. {{benefit.expiredAt | date: 'M/d'}}
            </p>
          </div>
          <p class="from" [innerHTML]='"From " + benefit.sourceUserName'></p>
        </div>
        <div class="benefit" *ngFor="let benefit of memberService.nonMemberBenefits$ | async">
          <div>
            <p class="text">{{benefit.displayName}}</p>
            <p class="expires" *ngIf="benefit.expiredAt">
              exp. {{benefit.expiredAt | date: 'M/d'}}
            </p>
          </div>
          <p class="from" [innerHTML]='"From " + benefit.sourceUserName'></p>
        </div>
      </div>
      <div>
        <button *ngIf="shouldShowMembership() | async" ysh-button-block (click)="didTapNext(1)">
          Next: Yoshi Membership
        </button>
        <button *ngIf="!(shouldShowMembership() | async)" ysh-button-block (click)="didTapNext(2)">
          Next: How Yoshi Works
        </button>
      </div>
    </div>

    <div *ngIf="flowIndex === 1" class="flow-content">
      <div class="membership">
        <img src="assets/images/welcome-icon-membership.svg" />
        <h3>Yoshi Membership</h3>
      </div>
      <div>
        <p>{{membershipSlideDescription() | async}}</p>
        <button ysh-button-block (click)="didTapNext(2)">Next: How Yoshi Works</button>
      </div>
    </div>

    <div *ngIf="flowIndex === 2" class="flow-content">
      <div class="how-yoshi-works">
        <img src="assets/images/welcome-icon-truck.svg" />
        <h3>How Yoshi Works</h3>
      </div>
      <div>
        <p>
          Select your preferred day and time and Yoshi will come by every week to top off your tank
          and service your vehicle.
        </p>

        <button ysh-button-block (click)="didTapNext(3)">Next: Price Matching</button>
      </div>
    </div>

    <div *ngIf="flowIndex === 3" class="flow-content">
      <div class="discounts">
        <img src="assets/images/welcome-icon-discounts.svg" />
        <h3>Price Matching</h3>
      </div>
      <div>
        <p>
          We give you the lowest Top-Tier™ gas price within a 2 mile radius! When you use our
          additional services you will also earn big gas discounts!
        </p>
        <button ysh-button-block (click)="didTapDismiss()">Let's Get Started!</button>
      </div>
    </div>
  </div>
</ion-content>
