<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="didTapDismiss()">
        <ion-icon name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #content class="page-vehicle-color typed-flow flow">
  <div class="flowItem active">
    <ion-label class="flow__title" stacked>..and the color?</ion-label>
    <ion-list>
      <ion-item button *ngFor="let color of colors" (click)="didSelectColor(color)">
        {{color}}
      </ion-item>
    </ion-list>
  </div>
</ion-content>
