<ion-content>
  <div ion-fixed class="request-received-modal">
    <div class="request-received-modal__header">
      <img src="./assets/images/gas-order-disclaimer.png" alt="" />
      <h3 class="request-received-modal__header__text">{{headerText}}</h3>
    </div>
    <div class="request-received-modal__body">
      <p class="request-received-modal__body__description">{{description}}</p>
      <button
        class="request-received-modal__body__button"
        ysh-button-block
        (click)="didTapSoundsGood()"
      >
        {{buttonText}}
      </button>
    </div>
  </div>
</ion-content>
