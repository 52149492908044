<ion-header>
  <ysh-order-flow-navbar
    [showFaqBtn]="false"
    (onDismiss)="didTapDismiss()"
    [showDismissBtn]="showDismissBtn"
    [showBackBtn]="showBackBtn"
    [showInDarkTheme]="true"
  >
  </ysh-order-flow-navbar>
</ion-header>
<ion-content padding class="notes-for-filler-wrapper">
  <h6 class="notes-title">{{addNoteTitle}}</h6>
  <ion-input
    placeholder="Delivery note"
    maxlength="60"
    value=""
    [(ngModel)]="note"
    #inputNote
  ></ion-input>
  <p class="note-text">{{exampleNote}}</p>
  <p
    class="note-text note-limit"
    [ngClass]="{'note-limit__danger': note?.length > MAX_NOTE_LENGTH}"
  >
    {{note?.length}}/{{MAX_NOTE_LENGTH}}
  </p>
  <button
    class="add-note-button"
    ysh-button-block
    [disabled]="!note?.trim().length || note?.length > MAX_NOTE_LENGTH"
    (click)="didTapAddNote()"
  >
    Add Note
  </button>
</ion-content>
