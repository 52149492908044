<ion-item class="repeat-pref-item" no-padding no-border>
  <ion-label>Preferences</ion-label>
  <div>
    <p>Repeat</p>
    <ion-select
      [(ngModel)]="selectedFrequency"
      [interfaceOptions]="{header:'Repeat every'}"
      (ngModelChange)="didChange($event)"
    >
      <ion-label>Repeat every</ion-label>
      <ion-select-option *ngFor="let option of frequencyOptions" [value]="option">
        {{option | titleForFrequency}}
      </ion-select-option>
    </ion-select>
  </div>
</ion-item>
