<ion-content class="price-per-gallon-details-modal-page">
  <div class="price-container">
    <div
      class="price-container__header"
      [ngClass]="{
      'fixed-price-header': hasFixedPrice,
      'lowest-price-match-header': true
    }"
    >
      <div class="lowest-price-match-header-content" *ngIf="!hasFixedPrice">
        <button class="dismiss-btn" ysh-button-icon name="close" (click)="didTapDismiss()"></button>
        <div class="map-container" id="pricing-screen-map"></div>
        <div
          class="gas-station-marker display-hidden"
          id="gas-station-marker-{{i}}"
          *ngFor="let station of gasStations; index as i"
        >
          <img
            [class.lowest-price]="isStationLowestPrice(station)"
            class="gas-station-marker__icon"
            src="./assets/images/GasStationLogos/{{station.brand.toLowerCase().split('-').join('_')}}.png"
            onerror="this.onerror=null;this.src='./assets/images/GasStationLogos/fallback.png'"
          />
          <div class="gas-station-marker__text text">
            <h4 class="text__lowest-price-label" *ngIf="isStationLowestPrice(station)">
              Price Match
            </h4>
            <h4 class="text__price" [class.text__lowest-price]="isStationLowestPrice(station)">
              {{priceForStation(station) | currency:'USD':'symbol':'1.2-2'}}
              <span *ngIf="isStationLowestPrice(station)">/ gal</span>
            </h4>
          </div>
        </div>
      </div>
      <div class="fixed-price-header-content" *ngIf="hasFixedPrice">
        <h4 class="price-container__content__yoshi-synergy" *ngIf="hasSynergy">
          Yoshi w/ Synergy™
        </h4>
        <h4 class="price-container__content__price">
          {{ getDiscountedPrice() < 0 ? 0 : getDiscountedPrice() |
          currency:'USD':'symbol':'1.2-2'}}/gal <span>🎉</span>
        </h4>
      </div>
    </div>
    <div class="price-container__content">
      <ng-container class="content-yoshi-synergy-discounted-price" *ngIf="!hasFixedPrice">
        <h4 class="price-container__content__yoshi-synergy" *ngIf="hasSynergy">
          Yoshi w/ Synergy™
        </h4>
        <h4 class="price-container__content__price">
          {{ getDiscountedPrice() < 0 ? 0 : getDiscountedPrice() |
          currency:'USD':'symbol':'1.2-2'}}/gal <span>🎉</span>
        </h4>
      </ng-container>
      <h4 class="price-container__content__title">How your price is calculated</h4>
      <div class="price-container__content__item">
        <ng-container class="price-container-price-match-label" *ngIf="!hasFixedPrice">
          <h4 class="label" *ngIf="closestStationOutsideRadius">Nearest Gas Station</h4>
          <h4 class="label" *ngIf="!closestStationOutsideRadius">Lowest Price Within 2 miles</h4>
        </ng-container>
        <ng-container class="price-container-fixed-price-label" *ngIf="hasFixedPrice">
          <h4 class="label">{{vehicle.fuelGrade}}</h4>
        </ng-container>
        <h4 class="price">{{ getBasePrice() | currency:'USD':'symbol':'1.2-2' }}</h4>
      </div>
      <div class="price-container__content__item" *ngIf="getLowestFixedPriceDiscount() > 0">
        <h4 class="label">{{ descriptionForLowestFixedPrice() }}:</h4>
        <h4 class="price">-{{ getLowestFixedPriceDiscount() | dollarsAndCents}}</h4>
      </div>
      <div class="price-container__content__item">
        <h4 class="label">Services Discount:</h4>
        <h4 class="price">-{{ getServiceDiscounts() | dollarsAndCents}}</h4>
      </div>
      <div class="price-container__content__item" *ngIf="getOtherDiscounts() > 0">
        <h4 class="label">Other Discounts:</h4>
        <h4 class="price">-{{ getOtherDiscounts() | dollarsAndCents}}</h4>
      </div>
      <div class="price-container__content__item price-container__content__item--highlighted">
        <h4 class="label">Your Price:</h4>
        <h4 class="price">
          {{ getDiscountedPrice() < 0 ? 0 : getDiscountedPrice() | currency:'USD':'symbol':'1.2-2'
          }}
        </h4>
      </div>
    </div>
    <div class="footer-content">
      <h4 class="daily-pricing-text">
        {{hasFixedPrice ? FIXED_DAILY_PRICING_DISCLAIMER : LOWEST_DAILY_PRICING_DISCLAIMER}}{{
        this.showOpisAttribution ? this.OPIS_ATTRIBUTION : "" }}
      </h4>
    </div>
  </div>
</ion-content>
