<ion-header>
  <ion-toolbar>
    <ion-buttons>
      <ion-button *ngIf="modal" (click)="dismiss()" slot="start">
        <ion-icon name="close-sharp" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="title">Add-ons</div>
  <div class="tag-line">Add these extras to your Yoshi visits</div>
  <ysh-service-card
    *ngFor="let serviceGroup of services"
    [selectedVehicle]="vehicleService.selectedVehicle$ | async"
    [selectedAddress]="orderSchedule.currentAddress$ | async"
    [showAddBtn]="true"
    [serviceGroup]="serviceGroup"
    [serviceIsAdded]="serviceMap[serviceGroup?.serviceType?.name]"
  >
  </ysh-service-card>
</ion-content>
<ion-footer>
  <ion-toolbar *ngIf="!modal" no-padding>
    <ysh-curved-actions-block>
      <button ysh-button-block action="primary" color="dark" (click)="didTapFinish()">
        {{actionText}}
      </button>
    </ysh-curved-actions-block>
  </ion-toolbar>
</ion-footer>
