<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ng-container *ngIf="!modal">
        <ion-back-button icon="chevron-back-sharp"></ion-back-button>
      </ng-container>
      <ion-button *ngIf="modal" (click)="didTapDismiss()">
        <ion-icon name="close-sharp" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Addresses</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="settings" background>
  <ion-list>
    <ion-item-divider></ion-item-divider>
    <ion-item button detail="true" (click)="didTapNewAddress()">Add Address</ion-item>
    <div>
      <ion-item-divider></ion-item-divider>
      <ion-item-sliding *ngFor="let userAddress of addresses$ | async">
        <ion-item
          ion-item
          detail="false"
          class="address-item"
          (click)="didSelectAddress(userAddress)"
          [class.selected]="isAddressSelected(userAddress)"
        >
          <ion-icon name="location-sharp" slot="start"></ion-icon>
          <div class="address-detail">
            <h3 class="street">{{userAddress.address.details.description}}</h3>
            <p class="city-state">
              {{userAddress.address.details.city}}, {{userAddress.address.details.state}}
            </p>
          </div>
          <ion-icon
            *ngIf="isAddressSelected(userAddress)"
            name="checkmark-sharp"
            slot="end"
          ></ion-icon>
          <p slot="end" *ngIf="!serviceAvailable(userAddress)" class="no-service">No Service</p>
        </ion-item>
        <ion-item-options>
          <ion-item-option color="danger" (click)="didTapDelete(userAddress)"
            >Delete</ion-item-option
          >
        </ion-item-options>
      </ion-item-sliding>
    </div>
  </ion-list>

  <ion-infinite-scroll (ionInfinite)="getNextPage($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading More...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
