<ysh-bottom-sheet (click)="didTapBackdrop()">
  <div class="service-type-sheet">
    <div class="service-type-sheet__header">
      <h3 class="title">{{ title }}</h3>
    </div>
    <div class="service-type-sheet__actions" [class.overflow]="services.length > 4">
      <div
        class="action"
        [class.disabled]="orderStatus[serviceGroup.serviceType.name]"
        *ngFor="let serviceGroup of services; index as i"
        (click)="didTapServiceItem($event, this.serviceGroup)"
      >
        <div class="action__icon">
          <img [src]="serviceGroup.serviceType.icon" />
        </div>
        <h6 class="action__label">{{serviceGroup?.serviceType.title}}</h6>
      </div>
    </div>
  </div>
</ysh-bottom-sheet>
