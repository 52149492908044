<ng-container *ngIf="slides.length"></ng-container>
<div class="ysh-gallery">
  <div
    class="ysh-gallery__slide"
    [class.rounded-slide]="rounded"
    [ngStyle]="{
      'min-width': 100 / slidesPerView + '%',
      'max-width': 100 / slidesPerView + '%',
      'margin-right': spaceBetween + 'px'
    }"
    *ngFor="let slide of slides; index as i"
    (click)="didTapSlide(i)"
  >
    <img [src]="slide" />
  </div>
  <div
    *ngIf="slides.length > maxImagesInSlider && showMore"
    class="ysh-gallery__slide--view-more rounded-slide"
    [ngStyle]="{ 'min-width': 100 / slidesPerView + '%' }"
    (click)="didTapViewMore()"
  >
    <p>View More</p>
  </div>
</div>
