<ion-content>
  <div class="timewindow-selector" slot="fixed">
    <div class="timewindow-selector__header header">
      <ysh-order-flow-header
        (onBackTapped)="didTapBack()"
        [isUpdatingOrder]="props.isModal"
        [service]="props.service"
        (onDismissTapped)="didTapDismiss()"
        (onAddressUpdated)="didUpdateAddress($event)"
      >
      </ysh-order-flow-header>
    </div>
    <div class="timewindow-selector__content content">
      <div class="routes-container" *ngIf="props.day.shifts?.length > 1">
        <button
          *ngFor="let shift of props.day.shifts"
          [ngClass]="{
            'route-cell': true,
            'route-cell--selected': form?.shift?.title === shift.title,
            'route-cell--disabled': !props.day.shiftAvailable(shift)
          }"
          [disabled]="!props.day.shiftAvailable(shift)"
          (click)="didSelectShift(shift)"
        >
          <h4>{{shift.title}}</h4>
        </button>
      </div>
      <div class="title" *ngIf="!props.service.ownerRequired">My car will be available from:</div>
      <div class="title" *ngIf="props.service.ownerRequired">I will be available from:</div>
      <div class="timewindow-slider" *ngIf="form.shift && form.window">
        <ion-item no-border no-padding>
          <ion-range
            dualKnobs="true"
            [(ngModel)]="form.window"
            [min]="form.shift.startTime.hour"
            [max]="computeUpperBound(form.shift?.startTime?.hour, form.shift?.endTime?.hour)"
            snaps="true"
            step="1"
            (ionChange)="sliderValueDidChange()"
          >
          </ion-range>
        </ion-item>
        <div class="value-labels">
          <ion-label [style.left]="this.sliderLabelInfo.lowerx" class="lower"
            >{{this.sliderLabelInfo.lowerLabel}}</ion-label
          >
          <ion-label [style.left]="this.sliderLabelInfo.upperx" class="upper"
            >{{this.sliderLabelInfo.upperLabel}}</ion-label
          >
        </div>
      </div>
      <div class="min-hours-needed" *ngIf="form.shift">
        Minimum of <strong>{{props.day.minimumWindowForShift(form.shift)}}</strong> hours is needed.
        🙏
      </div>
      <button
        ysh-button-block
        size="large"
        (click)="didSelectWindow()"
        [disabled]="!validTimeWindow"
      >
        Confirm {{ props.service?.serviceType.title || "Delivery" }} Time
      </button>
    </div>
  </div>
</ion-content>
