<ion-header>
  <ion-toolbar>
    <ion-buttons start>
      <ion-button (click)="didTapBack()">
        <ion-icon slot="icon-only" name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="typed-flow flow" padding>
  <ion-label class="flow__title" stacked>
    Appointments are available! Add your info to see scheduling options!
  </ion-label>
  <ion-input
    #inputFirstName
    [(ngModel)]="formData.firstName"
    type="text"
    placeholder="First Name"
    (keyup.enter)="didTapEnter()"
  ></ion-input>
  <ion-input
    #inputLastName
    [(ngModel)]="formData.lastName"
    type="text"
    placeholder="Last Name"
    (keyup.enter)="didTapEnter()"
  ></ion-input>
  <ion-input
    #inputEmail
    [(ngModel)]="formData.email"
    type="email"
    placeholder="Email Address"
    (keyup.enter)="didTapEnter()"
  ></ion-input>
  <input
    #inputPhone
    [textMask]="{mask: phoneMask, guide: false}"
    [(ngModel)]="formData.phone"
    type="tel"
    placeholder="Phone (415) 555-1234"
    (keyup.enter)="didTapEnter()"
  />
  <ion-input
    *ngIf="formData.inviteCode"
    #inputInviteCode
    [(ngModel)]="formData.inviteCode"
    type="text"
    disabled="true"
    placeholder="Invite Code"
  ></ion-input>
  <button ysh-button-block [disabled]="!formValidates()" (click)="didTapDone()">Continue</button>
  <p class="legal">
    By tapping "Continue" you agree to Yoshi’s
    <span (click)="openLink('https://www.startyoshi.com/terms/')">Terms of Service</span> and
    <span (click)="openLink('https://www.startyoshi.com/privacy')">Privacy Policy</span>
    You also agree to receive recurring text messages from Yoshi for the purposes of customer support, delivery notifications and order reminders. Msg and data rates may apply. Reply HELP for help and STOP to unsubscribe.
  </p>
</ion-content>
