<ng-container *ngIf="vehicle">
  <div class="vehicle-profile-information">
    <div class="vehicle-edit">
      <p class="vehicle-nick">
        <span *ngIf='vehicle.hasCustomNickname'>&ldquo;{{ vehicle.nickname }}&rdquo;</span>
        <span *ngIf='!vehicle.hasCustomNickname'>{{ vehicle.nickname }}</span>
      </p>
      <ion-button fill="clear" (click)="didTapEdit()">Edit</ion-button>
    </div>
    <div class="vehicle-details">
      <p class="vehicle-make" *ngIf="vehicle.makeAndModel">{{ vehicle.makeAndModel }}</p>
      <p class="license" *ngIf="vehicle.license">License: {{ vehicle.license }}</p>
      <p class="vin" *ngIf="vehicle.vin">VIN: {{ vehicle.vin }}</p>
      <p class="fuel-grade" *ngIf="vehicle.fuelType">{{ vehicle.fuelType.description }}</p>
      <button
        ysh-button
        class="vehicle-details__missing-info-cta"
        size="small"
        *ngIf="missingInfo"
        (click)="didTapEdit()"
      >
        Add {{ missingInfo }}
      </button>
    </div>
  </div>
</ng-container>
