<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button *ngIf="isModal" (click)="dismiss()">
        <ion-icon name="close-sharp" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="typed-flow flow">
  <div class="flowItem active corporate-promo-page">
    <ion-item no-border no-padding>
      <ion-label class="flow__title">{{companyEmployeeTitle}}</ion-label>
    </ion-item>
    <ion-input [(ngModel)]="entry" [placeholder]="placeholder"></ion-input>
    <div class="corporate-promo-page__terms-of-service">
      <ion-item no-border no-padding class="company-employee-toggle">
        <ion-label>{{companyEmployeeCertifyStatement}}</ion-label>
        <ion-toggle
          [checked]="isCompanyEmployee"
          (ionChange)="isCompanyEmployee = !isCompanyEmployee"
        ></ion-toggle>
      </ion-item>
      <ion-item no-border no-padding class="accept-terms-toggle">
        <ion-label>I agree to the <a (click)="didTapTerms()">Terms of Service</a></ion-label>
        <ion-toggle
          [checked]="termsOfServiceAccepted"
          (ionChange)="termsOfServiceAccepted = !termsOfServiceAccepted"
        ></ion-toggle>
      </ion-item>
    </div>
    <div class="corporate-promo-page__cta">
      <button ysh-button-block (click)="didTapSubmit()" [disabled]="!validates()">
        {{submitCTAText}}
      </button>
      <button ysh-button-block action="tertiary" (click)="didTapSkip()">{{skipCTAText}}</button>
    </div>
  </div>
</ion-content>
