<button
  class="ysh-day-cell"
  [class.selected]="selected"
  [class.small]="small"
  [disabled]="disabled"
  (click)="didTap()"
>
  <h5 class="name">{{ day.displayName }}</h5>
  <h5 class="relative-time">{{ day.nextDateString(false, false) }}</h5>
</button>
