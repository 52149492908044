<div class="map-container">
  <div #mapRef id="map"></div>
  <div class="pin-container" *ngIf="showPin" [class.restricted]="parkingRestricted">
    <div class="message" *ngIf="message">{{message}}</div>
    <div class="pin" [class.dragging]="dragging">
      <span>🚙</span>
      <ion-icon name="chevron-down-sharp"></ion-icon>
    </div>
  </div>
</div>
