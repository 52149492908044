<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="didTapDismiss()">
        <ion-icon name="close-sharp" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title>Edit Account</ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="didTapSave()" [disabled]="!dirty">Save</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="settings" background>
  <ion-item-divider>Profile</ion-item-divider>
  <ion-item>
    <ion-label>First Name</ion-label>
    <ion-input
      placeholder="First Name"
      type="text"
      (input)="dirty=true"
      (keyup.enter)="closeKeyboard()"
      [(ngModel)]="formData.firstName"
      name="firstName"
    ></ion-input>
  </ion-item>
  <ion-item>
    <ion-label>Last Name</ion-label>
    <ion-input
      placeholder="Last Name"
      type="text"
      (input)="dirty=true"
      (keyup.enter)="closeKeyboard()"
      [(ngModel)]="formData.lastName"
      name="lastName"
    ></ion-input>
  </ion-item>
  <ion-item>
    <ion-label>Phone</ion-label>
    <ion-input
      disabled
      placeholder="Enter Phone"
      type="number"
      (keyup.enter)="closeKeyboard()"
      [(ngModel)]="formData.phone"
      name="phone"
    ></ion-input>
  </ion-item>
  <ion-item>
    <ion-label>Email</ion-label>
    <ion-input
      placeholder="Enter Email"
      type="email"
      (input)="dirty=true"
      (keyup.enter)="closeKeyboard()"
      [(ngModel)]="formData.email"
      name="email"
    ></ion-input>
  </ion-item>
  <ion-item>
    <ion-label>DOB</ion-label>
    <ion-datetime
      [disabled]="dobDisabled"
      [(ngModel)]="formData.dateOfBirth"
      placeholder="Enter Birthday"
      [max]="maxDate"
      [displayFormat]="displayFormat"
      [pickerFormat]="pickerFormat"
      (ionChange)="dirty=true"
    ></ion-datetime>
  </ion-item>
</ion-content>
