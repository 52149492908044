<input
  class="ysh-input"
  [ngClass]="{
    'underline': appearence === 'underline',
    'outline': appearence === 'outline',
    'underline-drop': appearence === 'underline-drop',
    'outline-drop': appearence === 'outline-drop'
  }"
  [placeholder]="placeholder"
  [value]="value"
  [readonly]="readonly"
  (click)="click"
>
