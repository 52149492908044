<ng-container *ngIf="featureFlagReady">
  <ion-content id="LAYOUT-A" *ngIf="!showAltLayout" [class.visible]="layoutVisible">
    <div class="wrapper">
      <ion-item class="intro" no-padding no-border>
        <div>
          <img src="{{webflowVariant.imgIntroSrc}}" />
          <ion-text [innerHTML]="introText"></ion-text>
        </div>
      </ion-item>
      <ion-item no-padding no-border>
        <img class="img-main" src="{{webflowVariant.imgMainSrc}}" />
      </ion-item>
      <ion-item class="pricing" *ngIf="webflowVariant.pricingAndAvailability" no-border>
        <ion-text [innerHTML]="webflowVariant.pricingAndAvailability"></ion-text>
      </ion-item>
      <ng-template *ngTemplateOutlet="searchField"></ng-template>
      <ng-template *ngTemplateOutlet="startButton"></ng-template>
      <ion-item class="footer" no-padding no-border>
        <ion-text>
          <div [innerHTML]="webflowVariant.footerCTA"></div>
          Already have a Yoshi account? <a (click)="didTapLogin()">Sign in</a>
        </ion-text>
      </ion-item>
    </div>
  </ion-content>
  <ion-content id="LAYOUT-B" *ngIf="showAltLayout" [class.visible]="layoutVisible">
    <header>
      <img src="{{webflowVariant.headerImgSrc}}" />
      <ion-text>
        <h1 [innerHTML]="webflowVariant.headerText"></h1>
        <p [innerHTML]="webflowVariant.headerCaption"></p>
      </ion-text>
    </header>
    <main>
      <ng-template *ngTemplateOutlet="searchField"></ng-template>
      <ng-template *ngTemplateOutlet="startButton"></ng-template>
      <div class="login-link">Have a Yoshi account? <a (click)="didTapLogin()">Sign in</a></div>
      <img src="{{webflowVariant.imgMainSrc}}" />
      <ion-text>
        <h4 [innerHTML]="webflowVariant.valuePropHeading"></h4>
        <p [innerHTML]="webflowVariant.valuePropText"></p>
        <ul>
          <li *ngFor="let valuePropItem of webflowVariant.valuePropList" [innerHTML]="valuePropItem"></li>
        </ul>
        <p [innerHTML]="webflowVariant.valuePropDetailsText"></p>
        <ol>
          <li *ngFor="let valuePropDetailsItem of webflowVariant.valuePropDetailsList" [innerHTML]="valuePropDetailsItem"></li>
        </ol>
        <div *ngIf="faq?.length">
          <a (click)="didTapFAQS()">Read the FAQs</a> for additional information.
        </div>
      </ion-text>
    </main>
  </ion-content>
</ng-container>

<ng-template #searchField>
  <ion-item class="search-field-group" no-padding no-border *ngIf="!webflowVariant.virtual">
    <ion-label *ngIf="platform.is('mobile')" class="placeholder label" (click)="didTapNewAddress()">
      <ion-icon name="location-outline"></ion-icon>
      {{formData?.locationName ? formData.locationName : "Enter Service Address"}}
    </ion-label>
    <ion-searchbar
      *ngIf="platform.is('desktop')"
      #searchbar
      webstyle
      (click)="didTapSearch()"
      placeholder="Enter Service Address"
      search-icon="location-outline"
    >
    </ion-searchbar>
    <button [disabled]="!addressValidates()" (click)="didTapDone()">
      <ion-icon name="arrow-forward-outline"></ion-icon>
    </button>
  </ion-item>
</ng-template>

<ng-template #startButton>
  <button
    [disabled]="loadingVirtualServices || !serviceGroup"
    *ngIf="webflowVariant.virtual"
    class="start-button dark"
    (click)="didTapSchedule()"
    ysh-button-block
    large
  >
    <span *ngIf="serviceGroup">
      Tap To Schedule
      <ion-icon name="arrow-forward-outline"></ion-icon>
    </span>
    <ion-spinner *ngIf="loadingVirtualServices" name="dots" color="white"></ion-spinner>
    <span *ngIf="!serviceGroup && !loadingVirtualServices">Service Unavailable</span>
  </button>
</ng-template>

<ion-spinner *ngIf="!featureFlagReady" class="page-loading" name="dots"></ion-spinner>
