<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="didTapDismiss()">
        <ion-icon name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="typed-flow">
  <div class="flowItem active">
    <ion-label class="flow__title" stacked>Do you have a name for your {{makeAndModel}}?</ion-label>
    <ion-input
      #licenseinput
      placeholder="Example Betty, Buddy, Old Blue"
      [(ngModel)]="nickname"
    ></ion-input>
    <button
      class="skip-button"
      (click)="didTapNext()"
      ysh-button-block
      [action]='nickname?.trim() ? "primary" : "secondary"'
    >
      {{nickname?.trim() ? 'Next' : "My car doesn't have a name"}}
    </button>
  </div>
</ion-content>
