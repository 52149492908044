<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="didTapBack()">
        <ion-icon name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="typed-flow">
  <div class="flowItem active">
    <ion-label class="flow__title"> {{addLicensePlateTitle}}</ion-label>
    <ion-input
      class="license-plate-input"
      placeholder="License Plate Number"
      [maxlength]="MAX_LICENSE_LENGTH"
      [(ngModel)]="licensePlate"
      autofocus
    ></ion-input>
    <p>{{descriptionMsg}}</p>
    <button
      [disabled]="!licensePlate?.trim().length"
      class="add-license-btn"
      (click)="didTapNext()"
      ysh-button-block
      action="primary"
    >
      Save
    </button>
    <button
      *ngIf="allowSkip"
      class="add-license-btn"
      (click)="didTapNext()"
      ysh-button-block
      action="tertiary"
    >
      Skip this for now
    </button>
  </div>
</ion-content>
