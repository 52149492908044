<ng-container *ngIf="vehicle">
  <div class="fuel-status-component">
    <ion-item no-padding no-border detail="false">
      <ion-label> Fuel Status </ion-label>
    </ion-item>

    <div class="fuel-status">
      <div>
        <span class="fuel-status__tank-level">E</span>
        <span class="fuel-status__progress-bar">
          <span
            [ngStyle]="{ width: vehicle?.fuelLevel?.level * 100 + '%' }"
            [class]="'fuel-status__progress-bar__progress ' + fuelStatus"
          >
          </span>
        </span>
        <span class="fuel-status__tank-level">F</span>
      </div>
      <span *ngIf="vehicle?.fuelLevel?.createdAt">as of {{ momentsAgo }}</span>
      <span *ngIf="!vehicle?.fuelLevel?.createdAt && vehicle?.oemConnected"
        >No Fuel Information Yet</span
      >
    </div>
  </div>
</ng-container>
