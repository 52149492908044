<ion-content>
  <ion-toolbar>
    <ion-buttons slot="start">
      <button ysh-button-icon name="close" (click)="didTapDismiss()"></button>
    </ion-buttons>
  </ion-toolbar>
  <ion-slides
    #slides
    pager="true"
    (ionSlideDidChange)="slideChanged()"
    (ionSlideTap)="didTapSlide()"
  >
    <ion-slide *ngFor="let url of photos" class="photo-slide">
      <img [src]="url" />
    </ion-slide>
  </ion-slides>
</ion-content>
