<ion-header>
  <ion-toolbar>
    <ion-title>Recall Details</ion-title>
    <ion-buttons>
      <ion-button *ngIf="modal" (click)="didTapDismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <h3 class="recall-date">{{recall.recordCreationDate | date:'MMM d, y'}}</h3>
  <div class="recall-detail">
    <h3 class="recall-title">{{recall.componentDescription}}</h3>
    <p class="recall-description">{{recall.consequenceSummary}}</p>
    <div class="recall-summary" *ngIf="recall.defectSummary">
      <h3>Summary</h3>
      <p>{{recall.defectSummary}}</p>
    </div>
    <div class="recall-remedy">
      <h3>Remedy</h3>
      <p>{{recall.correctiveSummary}}</p>
    </div>
    <div class="recall-notes" *ngIf="recall.notes">
      <h3>Notes</h3>
      <p>{{recall.notes}}</p>
    </div>
  </div>
</ion-content>
