<ion-header>
  <ion-toolbar>
    <ion-buttons start>
      <ion-back-button icon="chevron-back-sharp"></ion-back-button>
      <ion-button *ngIf="isModal" fill="clear" (click)="didTapNotNow()">
        <ion-icon name="close-sharp" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding class="connect-toyota">
  <div ion-fixed>
    <div class="icons">
      <img src="assets/images/logo-header.svg" />
      <ion-icon name="add"></ion-icon>
      <img [src]="makeIcon" class="toyota-logo" />
    </div>

    <h2>Link your Yoshi and {{makePretty}} Accounts!</h2>
    <p class="deck">
      Yoshi is an official {{makePretty}} partner! When you connect your account, you will:
    </p>
    <div class="benefit">
      <div>
        <ion-icon name="checkmark-circle"></ion-icon>
      </div>
      <p>Have your {{makePretty}} vehicles imported into Yoshi automatically.</p>
    </div>
    <div class="benefit">
      <div>
        <ion-icon name="checkmark-circle"></ion-icon>
      </div>
      <p>
        Receive alerts that help you to schedule necessary services from Yoshi including fuel, tire
        checks, and oil changes.
      </p>
    </div>
    <div class="benefit">
      <div>
        <ion-icon name="checkmark-circle"></ion-icon>
      </div>
      <p>
        Allow a Yoshi Field Tech to open your fuel flap and locate your car on your day of service.
      </p>
    </div>
    <p class="fine-print">*Available for {{minYear}} {{makePretty}} models and newer<br /></p>

    <div class="buttons">
      <ion-button (click)="didTapConnectToyota()" expand="block" color="primary">
        Connect My Car!
      </ion-button>
      <ion-button
        class="not-now"
        *ngIf="isModal"
        (click)="didTapNotNow()"
        fill="clear"
        expand="block"
        color="primary"
      >
        Not Now
      </ion-button>
    </div>
  </div>
</ion-content>
