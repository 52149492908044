<div class="page-signup-flow">
  <ion-header>
    <ion-toolbar>
      <ion-buttons start>
        <ion-button *ngIf="flowIndex == 0" (click)="didTapDismiss()">
          <ion-icon slot="icon-only" name="close-sharp"></ion-icon>
        </ion-button>
        <ion-button *ngIf="flowIndex > 0" (click)="didTapBack()">
          <ion-icon slot="icon-only" name="chevron-back-sharp"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="typed-flow flow">
    <ion-list no-lines>
      <div class="item flowItem active">
        <ion-label class="flow__title" stacked>Hello! What's your first and last name?</ion-label>
        <ion-input
          #inputFirstName
          [(ngModel)]="formData.firstName"
          type="text"
          placeholder="First Name"
          (keyup.enter)="didTapEnter()"
        ></ion-input
        ><br />
        <ion-input
          #inputLastName
          [(ngModel)]="formData.lastName"
          type="text"
          placeholder="Last Name"
        ></ion-input>
        <button ysh-button-block (click)="didTapNext()" [disabled]="disabled || !nameValidates()">
          Next
        </button>
      </div>
      <div class="item flowItem">
        <ion-label class="flow__title" stacked>...and your email?</ion-label>
        <ion-input
          #inputEmail
          [(ngModel)]="formData.email"
          type="email"
          placeholder="example@gmail.com"
        ></ion-input>
        <button ysh-button-block (click)="didTapNext()" [disabled]="disabled || !emailValidates()">
          Next
        </button>
      </div>
      <div class="item flowItem">
        <ion-label class="flow__title" stacked
          >Where do you normally park your car (home or work)?</ion-label
        >
        <ion-label *ngIf="!formData.locationName" class="placeholder select"
          ><a (click)="didTapNewAddress()">Enter Address</a></ion-label
        >
        <ion-label *ngIf="formData.locationName" class="select"
          ><a (click)="didTapNewAddress()">{{formData.locationName}}</a></ion-label
        >
        <p *ngIf="!formData.locationName" class="location-or">OR</p>
        <ion-row *ngIf="!formData.locationName" id="locationButton">
          <ion-col>
            <button ysh-button-block action="secondary" (click)="didTapGetLocation()">
              Use Current Location
            </button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <button ysh-button-block [disabled]="!formValidates()" (click)="didTapDone()">
              Sign Up
            </button>
          </ion-col>
        </ion-row>
        <p>Hint: If your employer offers Yoshi as a benefit, enter in the street address of the closest building you park next to.</p>
      </div>
    </ion-list>
  </ion-content>
</div>
