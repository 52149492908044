<ion-toolbar class="service-navbar">
  <ion-buttons slot="start">
    <button ysh-button-icon *ngIf="!hideBackButton" name="back" (click)="onBackTapped()"></button>
  </ion-buttons>
  <ion-buttons slot="end" *ngIf="!isSourcedFromUpsellFlow">
    <button
      ysh-button-icon
      *ngIf="service==='gas_refill'"
      name="price"
      (click)="didTapPriceDetailIcon()"
    ></button>
  </ion-buttons>
</ion-toolbar>
