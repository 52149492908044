<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button *ngIf="!preventBackNavigation" (click)="onDismiss()">
        <ion-icon name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #content class="page-vehicle-year typed-flow flow">
  <div class="flowItem active">
    <ion-label class="flow__title" stacked>What year is your car?</ion-label>
    <ion-list>
      <ion-item button *ngFor="let year of years" (click)="didSelectYear(year)">
        {{year}}
      </ion-item>
    </ion-list>
  </div>
</ion-content>
