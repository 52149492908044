<ion-content>
  <div class="day-selector day-selector__container" slot="fixed">
    <div class="day-selector__header header">
      <ysh-order-flow-header
        [isUpdatingOrder]="props.editing"
        [hideBackbutton]="preventBackNavigation"
        [service]="props.service"
        (onBackTapped)="didTapBack()"
        (onAddressUpdated)="addressDidUpdate($event)"
        (onDismissTapped)="didTapDismiss()"
      >
      </ysh-order-flow-header>
    </div>
    <div class="day-selector__content content">
      <div class="content__days">
        <ysh-day-cell
          *ngFor="let day of availableDays; index as i"
          [selected]="isDaySelected(day)"
          [small]="availableDays?.length > 5"
          [disabled]="!isDayServiced(day)"
          [day]="day"
          (tap)="didTapOnDay(day)"
        >
        </ysh-day-cell>
      </div>
      <div
        class="content__repeat-weekly"
        *ngIf="props.service.isGas && this.props.allowFrequencySelect"
      >
        <ion-item no-border class="multiple-days-select">
          <ion-label><ion-icon name="repeat" slot="icon-only"></ion-icon></ion-label>
          <ion-select
            icon-chevron-down
            (ionChange)="didSelectGasFrequency($event)"
            [(ngModel)]="props.selectedGasFrequency"
            [interfaceOptions]="SELECT_OPTIONS_GAS"
          >
            <ion-select-option *ngFor="let option of frequencyOptions" [value]="option"
              >{{option}}</ion-select-option
            >
          </ion-select>
        </ion-item>
      </div>
      <div
        class="content__repeat-weekly"
        *ngIf="!props.service.isGas && props.service.frequencyOptions?.length > 1 && this.props.allowFrequencySelect"
      >
        <ion-item no-border class="multiple-days-select">
          <ion-label><ion-icon name="repeat" slot="icon-only"></ion-icon></ion-label>
          <ion-select
            icon-chevron-didSelectGasFrequency
            [(ngModel)]="form.frequency"
            [interfaceOptions]="SELECT_OPTIONS"
          >
            <ion-select-option
              *ngFor="let option of props.service.frequencyOptions"
              [value]="option"
              >Every {{option | titleForFrequency}}</ion-select-option
            >
            <ion-select-option *ngIf="!props.service.slotScheduling && !props.editing" [value]="0"
              >One Time</ion-select-option
            >
          </ion-select>
        </ion-item>
      </div>
      <div class="content__disclaimer-text">{{disclaimer()}}</div>
      <div
        class="content__disclaimer-text"
        *ngIf="props.showCutoff && program"
        (click)="didTapCutoffDisclaimer()"
      >
        Order by
        <b>{{loyaltyService.computeCutoffTimeString}}</b>
        for same day service!&nbsp;
        <ysh-loyalty-badge
          [color]="currentUser?.converted ? program.fullTier.color : WHITE_COLOR"
          [label]="currentUser?.converted ? program.fullTier.name : NEW_USER_LABEL"
        ></ysh-loyalty-badge>
      </div>
      <button
        ysh-button-block
        size="large"
        (click)="didSelectDays()"
        [disabled]="!form.days?.length"
      >
        Confirm {{ props.service.serviceType.title || "Delivery" }} Day
      </button>
    </div>
  </div>
</ion-content>
