<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button icon="chevron-back-sharp"></ion-back-button>
      <ion-button *ngIf="isModal" (click)="didTapNotNow()">
        <ion-icon name="close-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <div ion-fixed>
    <div class="icons">
      <img src="assets/images/logo-header.svg" />
      <ion-icon name="add"></ion-icon>
      <img src="assets/images/gm-icon-onstar.svg" />
    </div>

    <h2>Link your OnStar Account!</h2>
    <p class="deck">
      Yoshi is an official OnStar™ partner! When you connect your account, you will:
    </p>
    <div class="benefit">
      <div>
        <ion-icon name="checkmark-circle"></ion-icon>
      </div>
      <p>Have your OnStar™ vehicles imported into Yoshi automatically.</p>
    </div>
    <div class="benefit">
      <div>
        <ion-icon name="checkmark-circle"></ion-icon>
      </div>
      <p>
        Receive alerts that help you to schedule necessary services from Yoshi including fuel, tire
        checks, and more.
      </p>
    </div>
    <div class="benefit">
      <div>
        <ion-icon name="checkmark-circle"></ion-icon>
      </div>
      <p>
        Allow a Yoshi Field Tech to open your fuel flap and locate your car on your day of service.
      </p>
    </div>
    <p class="fine-print">*Available for {{MIN_YEAR}} models and newer</p>

    <div class="buttons">
      <button (click)="didTapConnectOnStar()" ysh-button-block>Connect My Car!</button>
      <button *ngIf="isModal" ysh-button-block action="tertiary" (click)="didTapNotNow()">
        Not Now
      </button>
    </div>
  </div>
</ion-content>
