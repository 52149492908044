<ion-header>
  <ion-toolbar>
    <ion-buttons start>
      <ion-button button *ngIf="flowIndex > 0" icon-only clear (click)="didTapBack()">
        <ion-icon name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="typed-flow flow page-update-user-flow">
  <ion-list no-lines>
    <div class="item flowItem" *ngIf="isMissingName">
      <ion-label class="flow__title" stacked>
        <span *ngIf="flowIndex === 0"
          >Hello! We need some info to complete your profile! What's your
        </span>
        <span *ngIf="flowIndex > 0">...and your</span>
        first and last name?
      </ion-label>
      <ion-input
        #inputName
        [(ngModel)]="fullName"
        type="text"
        placeholder="Full Name"
        (ionChange)="updateName()"
        (keyup.enter)="didTapEnter()"
      ></ion-input>
    </div>
    <div class="flowItem" *ngIf="isMissingPhoneNumber">
      <ion-label class="flow__title" stacked>
        <span *ngIf="flowIndex === 0"
          >Hello! We need some info to complete your profile! What is
        </span>
        <span *ngIf="flowIndex > 0">...and your</span>
        mobile phone number?
      </ion-label>
      <ion-input
        #inputPhone
        [(ngModel)]="formData.phone"
        type="tel"
        placeholder="Phone Number"
        maxlength="10"
        (keyup.enter)="didTapEnter()"
      ></ion-input>
    </div>
    <div class="flowItem" *ngIf="isMissingEmail">
      <ion-label class="flow__title" stacked>
        <span *ngIf="flowIndex === 0"
          >Hello! We need some info to complete your profile! What is
        </span>
        <span *ngIf="flowIndex > 0">...and your</span>
        email?
      </ion-label>
      <ion-input
        #inputEmail
        [(ngModel)]="formData.email"
        type="email"
        placeholder="Email"
        (keyup.enter)="didTapEnter()"
      ></ion-input>
    </div>
    <ion-row
      class="update-user-action update-user-action__next"
      *ngIf="flowIndex < (missingFieldsCount-1)"
    >
      <ion-col class="ion-padding">
        <button ysh-button-block (click)="didTapNext()" [disabled]="!enableNextButton()">
          Next
        </button>
      </ion-col>
    </ion-row>
    <ion-row
      *ngIf="flowIndex == (missingFieldsCount-1)"
      class="update-user-action update-user-action__done"
    >
      <ion-col class="ion-padding">
        <button ysh-button-block (click)="didTapDone()" [disabled]="!formValidates()">
          Finish
        </button>
      </ion-col>
    </ion-row>
  </ion-list>
</ion-content>
