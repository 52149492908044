<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button name="close" (click)="didTapDismiss()">
        <ion-icon name="close-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="cancellation-reasons-container">
    <div class="page-title">{{pageTitle}}</div>
    <div class="reasons-container">
      <div
        *ngFor="let reason of cancellationReasons"
        class="reason"
        (click)="didSelectReason(reason['value'])"
      >
        {{reason['value']}}
      </div>
    </div>
  </div>
</ion-content>
