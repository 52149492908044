<ion-header>
  <ion-toolbar>
    <ion-buttons start>
      <ion-button (click)="didTapDismiss()">
        <ion-icon slot="icon-only" name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="typed-flow flow">
  <ion-list no-lines>
    <div class="flowItem item active">
      <ion-label class="flow__title" stacked
        >We just sent you a pin, can you type it in below?</ion-label
      >
      <ion-input
        #inputPin
        [(ngModel)]="pin"
        [ngClass]="{'outline' : outlineInputStyle}"
        type="tel"
        placeholder="Type Pin"
        maxlength="4"
        (ionChange)="didTypePin($event?.detail?.value)"
        (keyup.enter)="didTapEnter()"
      ></ion-input>
      <button ysh-button-block (click)="didTapLogin()" expand="block" [disabled]="!formValidates()">
        Login
      </button>
    </div>
  </ion-list>
</ion-content>
