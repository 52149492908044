<ion-content>
  <div class="disclaimer-modal disclaimer-modal-container">
    <div class="disclaimer-modal__title">{{title}}</div>
    <div class="disclaimer-modal__detail">{{detail}}</div>
    <div class="disclaimer-modal__actions">
      <button ysh-button-block (click)="didTapTryWeekly()">Try weekly delivery</button>
      <button ysh-button-block action="tertiary" (click)="didTapStopWeekly()">
        Turn off weekly
      </button>
    </div>
  </div>
</ion-content>
