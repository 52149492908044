<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="didTapBack()">
        <ion-icon name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content padding>
  <div class="day-time-selector">
    <h2>Next, select a day and time to schedule your service!</h2>
    <div class="content">
      <h6>Select a day</h6>
      <div class="days">
        <ysh-day-cell
          *ngFor="let day of days; index as i"
          [day]="day"
          [selected]="isDaySelected(day)"
          [small]="days?.length > 5"
          [disabled]="!isDayServiced(day)"
          (tap)="didTapOnDay(day)"
        ></ysh-day-cell>
      </div>
      <h6>Select a time</h6>
      <div class="estimate">
        <span>The service is estimated to take {{ props.service?.stopDuration }} minutes.</span>
        <span *ngIf="props.userAddress.address.timeZoneShort as timeZone">
          All times in {{timeZone}}.</span
        >
      </div>
      <ion-spinner *ngIf="loading" name="dots"></ion-spinner>
      <div class="time" *ngIf="selectedDay?.slots?.length">
        <button
          ysh-pill-button
          *ngFor="let slot of selectedDay?.slots"
          [selected]="slot.startTimeString == form.slot?.startTimeString"
          [disabled]="!slot.available"
          (click)="didTapOnSlot(slot)"
        >
          {{slot.startTimeString}}
        </button>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar no-padding>
    <ysh-curved-actions-block>
      <button
        ysh-button-block
        [disabled]="!form.slot"
        size="large"
        action="primary"
        (click)="didTapDone()"
      >
        Confirm {{ props.service.serviceType.title || "Delivery" }} Time
      </button>
    </ysh-curved-actions-block>
  </ion-toolbar>
</ion-footer>
