<ion-content>
  <div class="pin-flow" slot="fixed">
    <div class="pin-flow__header">
      <ysh-location-picker
        (onUpdate)="mapDidUpdate($event)"
        [buttonText]="BUTTON_TEXT"
        [parkingCoord]="parkingCoord"
        [addressCoord]="addressCoord"
        [locationName]="props.userAddress.address.details.description"
        [isCorporateAddress]="props.userAddress.address?.isCorporate"
      ></ysh-location-picker>
      <ysh-order-flow-navbar
        [showBackBtn]="!props.editing"
        [showDismissBtn]="props.editing"
        [showFaqBtn]="true"
        (onDismiss)="didTapDismiss()"
        (onBack)="didTapBack()"
        [showInDarkTheme]="darkTheme"
      >
      </ysh-order-flow-navbar>
      <div class="selected-address" (click)="didTapChangeAddress()">
        <h4 class="selected-address__street-address">
          {{props.userAddress.address?.details.description}}
        </h4>
        <ion-icon class="select-address-dropdown" name="caret-down-sharp"></ion-icon>
      </div>
    </div>
    <div class="pin-flow__content content">
      <h4 class="title">Move the map to show us where you park!</h4>
      <div
        *ngIf="props.service.frequencyOptions.length || props.service.isGas"
        class="toggle-persistent-address"
      >
        <ion-item no-border no-padding>
          <ion-label>I always park at this spot at this address</ion-label>
          <ion-toggle
            [checked]="form.persistDeliveryLocation"
            (ionChange)="form.persistDeliveryLocation = !form.persistDeliveryLocation"
          ></ion-toggle>
        </ion-item>
      </div>
      <h6 class="add-note" *ngIf="!form.note?.length" (click)="didTapAddNote()">
        <ion-icon name="add-circle-outline"></ion-icon> {{NOTE_CTA}}
      </h6>
      <div class="note" *ngIf="form.note?.length">
        <label (click)="didTapAddNote()">{{form.note}}</label>
        <ion-button class="delete-note-button" fill="clear" (click)="didTapDeletedNote()">
          <ion-icon name="close-sharp" slot="icon-only"></ion-icon>
        </ion-button>
      </div>
      <div class="disclaimer">You can update your parking spot any time! 🤙</div>
      <button ysh-button-block size="large" [disabled]="disableActionButton" (click)="didTapNext()">
        Confirm {{ props.service.serviceType.title || "Delivery" }} Location
      </button>
    </div>
  </div>
</ion-content>
