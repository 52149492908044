<ion-header class="places-autocomplete-page">
  <ion-toolbar>
    <ion-searchbar
      slot="start"
      toolbar
      #searchBar
      [(ngModel)]="autocomplete.query"
      showCancelButton="never"
      [animated]="false"
      (ionChange)="updateSearch()"
    ></ion-searchbar>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="dismiss()" ion-button>Cancel</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-item
      button
      detail="false"
      *ngFor="let item of autocompleteItems"
      (click)="didSelectItem(item)"
    >
      {{ item.description }}
    </ion-item>
  </ion-list>
</ion-content>
