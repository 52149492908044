<ion-header>
  <ion-toolbar>
    <ion-title>Vehicle Photos</ion-title>
    <ion-buttons>
      <ion-button *ngIf="modal" (click)="didTapDismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="image-gallery-grid">
    <div class="cell" *ngFor="let photo of photos; index as i" (click)="didTapPhoto(i)">
      <img [src]="photo" />
    </div>
  </div>
</ion-content>
