<ysh-bottom-sheet (onDidDismiss)="onDismiss()">
  <div class="ysh-payment-sheet">
    <div class="ysh-payment-sheet__header">
      <h3 class="title">{{ title }}</h3>
    </div>
    <div class="ysh-payment-sheet__actions">
      <button ysh-button-block size="large" (click)="didTapCreditCard()">Add A Credit card</button>
      <button
        ysh-button-block
        size="large"
        class="apple-pay"
        (click)="didTapApplePay()"
        *ngIf="applePayAllowed"
      >
        <img class="apple-pay-logo" src="assets/images/apple-pay-logo-light.svg" />
      </button>
      <button
        ysh-button-block
        size="large"
        action="tertiary"
        (click)="didTapLater()"
        *ngIf="canSkipPayment"
      >
        Add payment method later
      </button>
    </div>
  </div>
</ysh-bottom-sheet>
