<div class="membership-card" [ngClass]="{'selected' : selected}">
  <div class="membership-card__title">{{tier.term}}</div>

  <div class="membership-card__price">
    {{displayFee() | currency:'USD':'symbol':'1.0-2'}}
    <div class="period">Per Month</div>
  </div>

  <div class="membership-card__terms" *ngIf="!description">
    Cancel Anytime
    <div *ngIf="tier.termAdverb" class="billed">Billed {{tier.termAdverb}}</div>
  </div>

  <div class="membership-card__terms description" *ngIf="description">
    <div class="description">{{description}}</div>
  </div>

  <div class="membership-card__badge" *ngIf="bestValue">Best Value</div>
</div>
