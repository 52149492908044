<div class="vehicle-limit-modal-wrapper">
  <h3 class="modal-title">{{ title }}</h3>
  <p class="modal-description">{{ description }}</p>
  <div class="modal-actions">
    <button ysh-button-block (click)="didTapCustomerServices()">Contact Customer Services</button>
    <button ysh-button-block action="secondary" (click)="didTapContactSales()">
      Contact Sales
    </button>
    <button ysh-button-block action="tertiary" (click)="dismiss()">Nevermind</button>
  </div>
</div>
